import React, { useEffect, useRef } from "react";
import "./notFound.scss";
import astro from "../../assets/Astronaut_-_Villaex_404.png";

const NotFound = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const moveImage = (e) => {
      const image = imageRef.current;
      const rect = image.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const moveX = (e.clientX - centerX) / 20;
      const moveY = (e.clientY - centerY) / 20;
  
      image.style.transform = `translate(${moveX}px, ${moveY}px)`;
    };
    const image = imageRef.current;
    image.style.transform = "translate(-50px, -50px)";
  
    window.addEventListener("mousemove", moveImage);
  
    return () => {
      window.removeEventListener("mousemove", moveImage);
    };
  }, []);
  


  return (
    <section id="not-found-wrapper">
      <div className="not-found">
        <span>404</span>
        <h1>Oops! Page not found.</h1>
        <p>
          It appears the page you were looking for couldn't be found. <br />
          Try going to Home Page by using the button below.
        </p>
    

        <img ref={imageRef} src={astro} alt="Image" />

        <div className="circles-left">
          <div className="circle circle-1" />
          <div className="circle circle-2" />
          <div className="circle circle-3" />
        </div>
        <div className="circles-right">
          <div className="circle circle-1" />
          <div className="circle circle-2" />
          <div className="circle circle-3" />
        </div>
      </div>
    </section>
  );
};

export default NotFound;
