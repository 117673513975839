export const HeaderOption=[
    {
        id:"1", title:"Home", path:"/"
    },
    {
        id:"2", title:"Case Studies", path:"/case-studies"
    },
    {
        id:"3", title:"Careers", path:"/careers"
    },
    {
        id:"4", title:"Blogs", path:"/blogs"
    },
    {
        id:"5", title:"Contact Us", path:"/contact-us"
    },
];