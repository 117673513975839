

import { useRoutes } from "react-router-dom";
import "./style/contactform.scss"
import "./style/styleSheet.scss";
import "./style/vt-style.css"
import { Routes } from "./config/Routes";
import { Suspense, useEffect } from "react";
import Cookies from "./components/cookies/Cookies";
import BlogCategory from "./components/blogs/category/BlogCategory";
import { GenerateSitemap } from "./config/GenerateSiteMap";
import BounceLoader from "react-spinners/BounceLoader";

function App() {
  const elements = useRoutes(Routes);
  useEffect(() => {
    window.scrollTo(0, 0);
    GenerateSitemap();
  }, [elements])
  return (
    <>
    <Suspense fallback={
        <div className="loader-overlay">
        <BounceLoader color="#5b25a0" size={100} className="loader" />
      </div>
    }>
    {elements}
      <Cookies />
    </Suspense>
   
    </>
  );
}

export default App;
