import React from "react";
import callUs from "../../assets/11/Call-Us-White-01.png";
import contact from "../../assets/11/Mail-2-01.png";
import Business from "../../assets/11/Business-Inquiries-2.png";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer>
        {/* <section className="footer-top vt-section">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <img
                    style={{ "margin-top": "20px" }}
                    height={50}
                    src={callUs}
                  />
                  <span>
                    <h4>Call Us</h4>
                    <p>
                      <a href="tel:(551)5540198">(551) 554 0198</a>
                    </p>
                  </span>
                </div>
                <div className="col-md-4">
                  <img
                    style={{ "margin-top": "20px" }}
                    height={50}
                    src={contact}
                  />
                  <span style={{ display: "block" }}>
                    <h4>Contact Us</h4>
                    <p>
                      <a href="mailto:corp@villaextechnologies.com">
                        corp@villaextechnologies.com
                      </a>
                    </p>
                  </span>
                </div>
                <div className="col-md-4">
                  <img
                    style={{ "margin-top": "20px" }}
                    height={50}
                    src={Business}
                  />
                  <span>
                    <h4>Business Inquiries</h4>
                    <p className="time-footer">10 AM - 08 PM</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <h2 style={{ textAlign: "center" }}>Connect With Us</h2>
              <ul>
                <li>
                  <Link
                    to="https://www.facebook.com/VillaexTechnologies/"
                    target="_blank"
                  >
                    <FaFacebookF size={20} />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/villaextech" target="_blank">
                    <FaTwitter size={20} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/villaex-technologies"
                    target="_blank"
                  >
                    <FaLinkedinIn size={20} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/villaex-technologies"
                    target="_blank"
                  ></Link>
                  <Link
                    to="https://www.instagram.com/villaextechnologies/"
                    target="_blank"
                  >
                    <FaInstagram size={20} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        <section className="footer-bottom vt-section">
          <div className="container-wrapper clearfix">
            <div className="section-1">
              <h2>QUICK LINKS</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                <li>
                  <Link to="/case-studies">Case Studies</Link>
                </li>
              </ul>
              <ul style={{ "max-width": "170px" }}>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <div className="row ">
                  <li className="col-3">
                    <Link
                      to="https://www.facebook.com/VillaexTechnologies/"
                      target="_blank"
                    >
                      <FaFacebookF size={20} />
                    </Link>
                  </li>
                  <li className="col-3">
                    <Link to="https://twitter.com/villaextech" target="_blank">
                      <FaTwitter size={20} />
                    </Link>
                  </li>
                  <li className="col-3">
                    <Link
                      to="https://www.linkedin.com/company/villaex-technologies"
                      target="_blank"
                    >
                      <FaLinkedinIn size={20} />
                    </Link>
                  </li>
                  <li className="col-3">
                    <Link
                      to="https://www.instagram.com/villaextechnologies/"
                      target="_blank"
                    >
                      <FaInstagram size={20} />
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
            <div className="section-2">
              <h2>
                SERVING IN 25+ COUNTRIES FOR WEB, SOFTWARE &amp; MOBILE APP
                DEVELOPMENT AND DESIGN
              </h2>
              <p>
                United States (USA), Canada, United Kingdom (UK), Australia, New
                Zealand, Ireland, Belgium, Spain, Netherlands, Sweden, Nigeria,
                Germany, Japan, Singapore etc.
              </p>
              <Link to="/start-project" className="request-quote">
                Request a Quote
              </Link>
            </div>
          </div>
          <div className="container-wrapper footer-terms">
            <p className="grey-color">
              All product and company names are trademarks™ or registered ®
              trademarks of their respective holders.
              <span style={{ display: "block" }}>
                All Rights Reserved &nbsp; © &nbsp;{" "}
                <Link to="/">Villaex Technologies</Link> | &nbsp;{" "}
                <Link to="/terms-and-privacy" target="_blank">
                  Terms
                </Link>{" "}
                &amp;{" "}
                <Link to="/terms-and-privacy" target="_blank">
                  {" "}
                  Privacy
                </Link>{" "}
              </span>
            </p>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
