import bits from "../assets/11/Bits-Gravity.png"
import bakary from "../assets/11/Bakery-Shop.png"
import hidden from "../assets/11/The-Hidden-Valleys.png"
import ausss from "../assets/11/Ausss.png"
import white from "../assets/11/White-Gloves-Concierge.png"
import curvy from "../assets/11/CurvyEgg.png"
import home from "../assets/11/247-Home-Services.png"
import cadi from "../assets/11/Cadi-deals.png"
import alm from "../assets/11/ALM-Consulting.png"
import hanged from "../assets/11/hanged-logo.png"
import labbaiyk from "../assets/11/labbaik-logo.png"
import mega from "../assets/11/mega-logo.png"
import php from "../assets/11/php-logo.png"
import wizi from "../assets/11/wizi-logo.png"
import spectrum from "../assets/11/spectrum-logo.png"
import sharahu from "../assets/11/shahrahu-logo.png"
import digi from "../assets/11/24-7DigiMedia.png"
import pidm from "../assets/11/pidm-logo.png"
import phuppi from "../assets/11/Phuppi-logo-black.png"
import bgbulax from "../assets/11/php-bg.png"
import image from "../assets/11/php-main.png"
import bgausss from "../assets/11/alm-bg.png"
import digimedia from "../assets/11/digimedia-main.png"
import almimage from "../assets/11/alm-main.png"
import bghanged from "../assets/11/hanged-bg.png"
import hangedimage from "../assets/11/hanged-main.png"
import bglabbaiyk from "../assets/11/labbaik-bg.png"
import labbaiykimage from "../assets/11/labbaik-main.png"
import bgmega from "../assets/11/mega-bg.png"
import megaimage from "../assets/11/mega-main.png"
import bgwizi from "../assets/11/wizi-bg.png"
import wiziimage from "../assets/11/wizi-main.png"
import bgspectrum from "../assets/11/spectrum-bg.png"
import spectrumimage from "../assets/11/spectrum-main.png"
import bgsharahu from "../assets/11/shahrahu-bg.png"
import sharahuimage from "../assets/11/shahrahu-main.png"
import bgdigi from "../assets/11/digimedia-bg.png"
import digiimage from "../assets/11/digimedia-main.png"
import bgpidm from "../assets/11/pidm-bg.png"
import pidmimage from "../assets/11/pidm-main.png"
import bgphuppi from "../assets/11/phuppi-bg.png"
import phuppiimage from "../assets/11/phuppi-mega.png"

import konnectx from '../assets/case-studies/konnectx-logo.png'
import konnectxMain from '../assets/case-studies/konnectx-feature.png'
import skullsludo from '../assets/case-studies/skulls-logo.png'
import skullsMain from '../assets/case-studies/skulls-feature.png'
import ums from '../assets/case-studies/ums-logo.png'
import umsMain from '../assets/case-studies/ums-feature.png'
import eblubox from "../assets/case-studies/eblubox-logo.png"
import trans from "../assets/case-studies/trans-logo.png"
import milliTello from '../assets/case-studies/milli-tello-logo.png'

export const Projects = [
    {
        id: "0", title: "Skulls Ludo", path: `/case-study/:name`, src: skullsludo,
        name: "SkullsLudo",
        metaTitle: 'Innovating Gaming with NFTs and Cryptocurrency',
        metaDescription: 'Explore the Skulls Ludo case study, a groundbreaking fusion of NFTs and cryptocurrency in gaming. Witness the revolution in immersive gameplay and financial opportunities.',
        tagline: 'Revolutionizing Gaming with NFTs and Cryptocurrency',
        briefDescription: "Skulls Ludo is an innovative game that operates at the intersection of NFTs (Non-Fungible Tokens) and cryptocurrency. It offers a unique gaming experience where players can engage in strategic gameplay while also delving into the world of digital assets. Through the use of NFTs, each game piece in Skulls Ludo possesses its own distinct value and rarity, adding an extra layer of excitement and collectability to the gaming experience.",
        img: skullsMain,
        imgTag: '',
        imgText: 'Skulls Ludo is an innovative gaming company at the forefront of the intersection between NFTs and cryptocurrency. Their game combines strategic gameplay with unique NFT game pieces, each possessing its own value and rarity. By integrating cryptocurrency, players can earn rewards, trade assets, and participate in a dynamic in-game economy. Skulls Ludo offers an immersive and cutting-edge gaming experience, appealing to enthusiasts seeking both fun and potential financial opportunities.',
        mainDescription: `
        <p>
        <strong style="font-weight: normal;" id="docs-internal-guid-2d5f4013-7fff-08b7-c228-af0551bf7eec">
            <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt; text-align: left;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Problem Identification: Challenges Faced by Skulls Ludo in the NFT and Cryptocurrency Gaming Market
                </span>
            </p>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Skulls Ludo faces challenges in a competitive gaming industry, where innovation and differentiation are crucial. The integration of NFTs and cryptocurrency brings complexities of user adoption and understanding. Security of
                    blockchain-based assets and transactions is vital to prevent fraud and hacking. Moreover, the volatility of cryptocurrency prices poses risks to the in-game economy. Overcoming these challenges is essential for Skulls Ludo
                    to maintain its leadership in the NFT and cryptocurrency gaming market.
                </span>
            </p>
            <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 16pt;
                    "
                >
                    Strategies and Solutions:
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Differentiation and Innovation:
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Skulls Ludo should focus on continuous innovation and differentiation to stand out in the competitive gaming market. This can involve introducing unique gameplay features, collaborating with renowned artists for exclusive
                    NFT designs, or exploring new genres that combine NFTs and cryptocurrency.
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    User Education and Adoption:&nbsp;
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    To overcome the complexities of NFTs and cryptocurrency, Skulls Ludo should invest in user education and adoption strategies. This includes providing clear and user-friendly tutorials, guides, and FAQs that explain the
                    benefits and processes of NFTs and cryptocurrency within the game. Hosting webinars or community events can also help foster a supportive environment where players can learn and engage with these technologies.
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Robust Security Measures:&nbsp;
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Skulls Ludo should prioritize implementing robust security measures to protect players' blockchain-based assets and transactions. This can include employing advanced encryption techniques, implementing multi-factor
                    authentication, conducting regular security audits, and partnering with reputable blockchain security firms to identify and address potential vulnerabilities.
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Stabilizing the In-game Economy:&nbsp;
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    To mitigate the impact of cryptocurrency price volatility on the in-game economy, Skulls Ludo can implement strategies to stabilize the value of in-game rewards and assets. This can involve creating a system that pegs the
                    value of rewards to a stable currency or implementing dynamic algorithms that adjust rewards based on the current cryptocurrency market conditions.
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Community Engagement and Feedback:&nbsp;
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Skulls Ludo should actively engage with its player community, listen to their feedback, and incorporate their suggestions into the game's development. This can be done through regular communication on social media platforms,
                    forums, and dedicated community channels. By involving the players in the decision-making process, the company can build a loyal and supportive community while continuously improving the gaming experience.
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Strategic Partnerships:
                </span>
            </h3>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Collaborating with other established players in the NFT and cryptocurrency space can provide valuable synergies and help expand Skulls Ludo's reach. Partnerships can involve cross-promotion, co-creation of exclusive content,
                    or integration with other NFT marketplaces or cryptocurrency exchanges to increase visibility and accessibility.
                </span>
            </p>
            <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 16pt;
                    "
                >
                    Development and Results&nbsp;
                </span>
            </p>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Differentiation and Innovation:
                </span>
            </h3>
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Introduction of exciting gameplay features and collaborative multiplayer modes.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Integration of augmented reality for an immersive gaming experience.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Collaboration with renowned artists and designers for exclusive NFT game pieces.
                        </span>
                    </p>
                </li>
            </ul>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    User Education and Adoption:
                </span>
            </h3>
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Development of intuitive tutorials, comprehensive guides, and interactive learning materials.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Organization of regular webinars and community events to foster learning and knowledge exchange.
                        </span>
                    </p>
                </li>
            </ul>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Robust Security Measures:
                </span>
            </h3>
            <br />
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Implementation of advanced encryption techniques and multi-factor authentication.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Conducting regular security audits and partnering with reputable blockchain security firms.
                        </span>
                    </p>
                </li>
            </ul>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Stabilizing the In-game Economy:
                </span>
            </h3>
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Development of a dynamic algorithm to adjust in-game rewards based on real-time market conditions.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Ensuring a stable and consistent gaming experience for players.
                        </span>
                    </p>
                </li>
            </ul>
            <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(67, 67, 67);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 14pt;
                    "
                >
                    Community Engagement and Feedback:
                </span>
            </h3>
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Active communication on social media platforms, forums, and dedicated community channels.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Incorporation of player feedback and ideas into game development decisions.
                        </span>
                    </p>
                </li>
            </ul>
            <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Significant growth in user adoption and player engagement.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Appreciation of the value of NFT game pieces over time.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Industry recognition and accolades for innovative approach and technology.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Strong and loyal player community.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style="
                        list-style-type: disc;
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        font-size: 11pt;
                    "
                    aria-level="1"
                >
                    <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                        <span
                            style="
                                font-family: Arial;
                                color: rgb(0, 0, 0);
                                background-color: transparent;
                                font-weight: 400;
                                font-style: normal;
                                font-variant: normal;
                                text-decoration: none;
                                vertical-align: baseline;
                                white-space: pre-wrap;
                                font-size: 11pt;
                            "
                        >
                            Positioning as a leader in the NFT and cryptocurrency gaming market.
                        </span>
                    </p>
                </li>
            </ul>
            <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 700;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 16pt;
                    "
                >
                    Conclusion
                </span>
            </p>
            <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    In conclusion, Skulls Ludo's case study demonstrates their successful journey in the NFT and cryptocurrency gaming market. Through differentiation, user education, robust security measures, stabilization of the in-game
                    economy, community engagement, and strategic partnerships, the company achieved significant growth and positioned itself as a leader in the industry. Their focus on innovation and player-centric approaches fostered a loyal
                    community and garnered industry recognition. Skulls Ludo stands as a testament to the potential of combining gaming, NFTs, and cryptocurrency to create a unique and immersive gaming experience.
                </span>
            </p>
        </strong>
    </p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Targeted Advertising and Influencer Partnerships',
            },
            {
                sol: 'Engaging Content Creation',
            },
            {
                sol: 'Financial Management and Monetization',
            },
            {
                sol: 'Scalability and Technical Infrastructure'
            },
        ]
    },
    {
        id: "1", title: "KonnectX", path: `/case-study/:name`, src: konnectx,
        name: "KonnectX",
        metaTitle: 'Boosting Customer Retention and Satisfaction in the Telecommunications Industry',
        metaDescription: 'Explore how KonnectX, a telecommunications company, addressed customer retention and satisfaction challenges through strategic management.',
        tagline: 'Boosting Customer Retention and Satisfaction in the Telecommunications Industry',
        briefDescription: "KonnectX.us provides great Internet Services with free Modem and Wifi, HDTV, Mobile, and Phones. Watch live your favorite TV channels, even more than 170 HDTV channels that include Top Sports and Entertainment Channels and most Premium Movie Channels. Provides Internet access to companies, families, and even mobile users. No credit checks and No deposits. Get your services with amazing deals today! Order your bundle and save up to $20 per month for 1 year.",
        img: konnectxMain,
        imgTag: 'konnectx',
        imgText: 'KonnectX is a telecommunications company specializing in Internet, TV, and phone services. Despite offering attractive deals and a diverse channel lineup, the company has faced challenges in customer retention and satisfaction. Issues with customer service, billing, and service quality have contributed to a decline in customer loyalty. KonnectX is dedicated to improving its services and enhancing the customer experience to remain competitive in the telecommunications industry.',
        mainDescription: `
        <p>
    <br />
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Problem Identification: Customer Retention &amp; Satisfaction Decline:
        </span>
    </strong>
    <br />
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 11pt;
            "
        >
            KonnectX a telecommunications company, offers a range of Internet, TV, and phone services with attractive deals and a diverse channel lineup. Despite the company's competitive offerings, there has been a noticeable decline in
            customer retention and satisfaction rates. The problem stems from several factors, including inadequate customer service, billing issues, and inconsistent service quality. USSSConnect recognizes the need to address these
            challenges to improve customer loyalty and maintain a competitive edge in the industry.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt; text-align: left;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Solution and Strategy:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Enhancing Customer Service:
        </span>
    </strong>
</h3>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Increase staffing levels to reduce wait times and improve response rates.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Implement training programs to enhance customer service representatives' skills.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Utilize customer feedback mechanisms to identify areas for improvement.
                </span>
            </strong>
        </p>
    </li>
</ul>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Improving Billing Processes:
        </span>
    </strong>
</h3>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Simplify billing statements and ensure transparency in pricing and charges.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Provide clear explanations of fees and charges to avoid confusion.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Establish a dedicated billing support team to address customer concerns promptly.
                </span>
            </strong>
        </p>
    </li>
</ul>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Enhancing Service Quality:
        </span>
    </strong>
</h3>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Conduct regular infrastructure maintenance and upgrades.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Proactively monitor network performance to identify and resolve issues.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Provide compensation or service credits for customers affected by disruptions.
                </span>
            </strong>
        </p>
    </li>
</ul>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Strengthening Customer Engagement:
        </span>
    </strong>
</h3>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Develop personalized communication channels to engage with customers.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Implement loyalty programs and rewards to incentivize long-term relationships.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Encourage customer feedback and suggestions to continuously improve services.
                </span>
            </strong>
        </p>
    </li>
</ul>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Monitoring and Continuous Improvement:
        </span>
    </strong>
</h3>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Establish key performance indicators to measure satisfaction and issue resolution rates.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Analyze customer feedback to identify trends and areas for improvement.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Conduct periodic audits to ensure adherence to best practices and standards.
                </span>
            </strong>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            <br />
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            By implementing these strategies, KonnectX Telecom can address the identified challenges, improve customer retention and satisfaction, and maintain a competitive edge in the telecommunications industry.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Development and Results:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            KonnectX Telecom implemented the strategies outlined above to address the identified challenges are here:
        </span>
    </strong>
</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Staffing levels were increased, training programs were implemented, and customer feedback mechanisms were utilized to enhance customer service.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Billing processes were improved by simplifying statements, providing clear explanations, and establishing a dedicated billing support team.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Service quality was enhanced through regular infrastructure maintenance, proactive monitoring, and providing compensation for disruptions.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Customer engagement was strengthened by developing personalized communication channels, implementing loyalty programs, and encouraging feedback.
                </span>
            </strong>
        </p>
    </li>
</ul>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Customer satisfaction levels improved significantly due to enhanced customer service and improved billing processes.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Customer complaints and concerns were addressed more promptly, leading to increased customer retention.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Service quality improved, resulting in fewer service disruptions and higher customer satisfaction.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    The implementation of loyalty programs and rewards incentivized customers to stay with KonnectX Telecom.
                </span>
            </strong>
        </p>
    </li>
    <li
        dir="ltr"
        style="
            list-style-type: disc;
            font-family: Arial;
            color: rgb(0, 0, 0);
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            font-size: 11pt;
        "
        aria-level="1"
    >
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
            <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
                <span
                    style="
                        font-family: Arial;
                        color: rgb(0, 0, 0);
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre-wrap;
                        font-size: 11pt;
                    "
                >
                    Monitoring and continuous improvement efforts led to ongoing enhancements in service quality and customer satisfaction.
                </span>
            </strong>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Conclusion:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-01c854c0-7fff-2a59-7f95-b7948a3f9786">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            In conclusion, the case study "Boosting Customer Retention and Satisfaction in the Telecommunications Industry" highlights the challenges faced by KonnectX Telecom and the strategies implemented to address them. By focusing on
            enhancing customer service, improving billing processes, enhancing service quality, strengthening customer engagement, and monitoring performance, KonnectX Telecom was able to achieve significant improvements in customer
            retention and satisfaction rates. The company's efforts resulted in higher customer satisfaction, reduced service disruptions, improved billing transparency, and increased customer engagement. These positive outcomes positioned
            KonnectX Telecom as a competitive player in the telecommunications industry and demonstrated the importance of prioritizing customer-centric strategies to drive business success.
        </span>
    </strong>
</p>
<br />

        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Customer-centric Approach',
            },
            {
                sol: 'Operational Efficiency',
            },
            {
                sol: 'Continuous Improvement',
            },
            {
                sol: 'Talent Development and Engagement'
            },
        ]
    },
    {
        id: "2", title: "The UMS", path: `/case-study/:name`, src: ums,
        name: "TheUMS",
        metaTitle: 'Case Study: Overcoming Challenges and Achieving Success ',
        metaDescription: 'Discover how Ultra Medical Supplies overcame challenges and achieved success in the healthcare industry through strategic management strategies.',
        tagline: 'Streamlining Healthcare Revenue Cycle Management',
        briefDescription: "Ultra Medical Supplier is a reputable company specializing in medical billing services, catering to the needs of insurance companies and hospitals. With their expertise and comprehensive understanding of the complex healthcare billing landscape, they ensure seamless and efficient processes for both insurance claims and billing transactions. Ultra Medical Supplier streamlines the billing procedures, minimizing errors and maximizing reimbursements for healthcare providers.",
        img: umsMain,
        imgTag: '',
        imgText: 'Ultra Medical Supplies is a reputable company operating in the United States, specializing in precise and efficient medical billing services for insurance companies and hospitals. With a comprehensive understanding of the complex healthcare billing landscape in the US, their experienced team ensures seamless processes, minimizing errors and maximizing reimbursements. They navigate the intricacies of insurance regulations and policies specific to the US market, providing accurate and timely submissions.',
        mainDescription: `
        <p><br /></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt; text-align: left;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Problem Identification: Client Dissatisfaction and Business Loss due to Inefficient Billing and Non-compliance
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Clients expect reliable and efficient billing services that adhere to industry regulations. Failing to meet these expectations can lead to client dissatisfaction, attrition, and potential loss of business for UMS. In a
            competitive market, client satisfaction is crucial for maintaining long-term relationships, securing referrals, and sustaining UMS's position as a reputable medical billing service provider.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Solution and Strategy:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Streamlining Billing Processes and Implementing Modern Systems
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            UMS should invest in upgrading its billing processes and adopting modern technology systems. This includes implementing electronic health records (EHR) systems, advanced billing software, and automation tools. These upgrades can
            enhance efficiency, accuracy, and speed in managing billing operations, leading to reduced errors and faster claim submissions.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Continuous Regulatory Compliance Monitoring and Training
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            UMS needs to establish a dedicated team responsible for monitoring and staying updated with changing healthcare regulations. Regular training sessions should be conducted to ensure all employees are knowledgeable about the
            latest compliance requirements. This proactive approach will minimize the risk of compliance issues and enable UMS to maintain its reputation as a reliable and compliant billing service provider.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Client Engagement and Satisfaction Enhancement
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            UMS should prioritize client satisfaction by actively engaging with clients and addressing their concerns. Regular communication channels should be established to receive feedback and suggestions. Client satisfaction surveys can
            provide valuable insights for improving services. UMS should also focus on building long-term relationships with clients by demonstrating reliability, responsiveness, and personalized attention to their specific needs.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Scalability Planning and Resource Allocation
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            To address scalability challenges, UMS should develop a strategic plan for growth. This involves assessing resource requirements, such as staffing and technology infrastructure, to accommodate increasing volumes of billing
            transactions. Scaling up operations should be done strategically to ensure that service quality and efficiency are maintained.
        </span>
    </strong>
</p>
<p>
    <strong style="font-weight: normal;" id="docs-internal-guid-237b59ae-7fff-d4bc-de28-f055ae60f473">
        <br />
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Continuous Improvement and Quality Assurance
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                UMS should establish a culture of continuous improvement by implementing quality assurance processes. Regular audits of billing operations and performance metrics should be conducted to identify areas for enhancement. This
                includes tracking key performance indicators (KPIs) such as claim accuracy, turnaround time, and reimbursement rates. Feedback from audits should be utilized to implement corrective measures and optimize overall performance.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                By implementing these solutions and strategies, UMS can enhance its billing processes, ensure compliance, boost client satisfaction, and position itself as a leading and reliable medical billing service provider in the
                competitive market.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Development and Results
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Streamlined Billing Processes:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                UMS successfully implemented modern technology systems, including electronic health records (EHR) and advanced billing software. This resulted in streamlined billing processes, improved efficiency, and reduced errors in
                claim submissions.
            </span>
        </p>
        <br />
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Compliance Monitoring and Training:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                UMS established a dedicated team to monitor changing healthcare regulations and provided regular training sessions for employees. This ensured that UMS stayed updated with compliance requirements and minimized the risk of
                compliance issues.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Client Engagement and Satisfaction:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                UMS actively engaged with clients established effective communication channels, and implemented client feedback mechanisms. This proactive approach enhanced client satisfaction by addressing concerns and providing
                personalized attention to their needs.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <br />
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhanced Efficiency and Accuracy:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                With streamlined billing processes and modern technology systems, UMS achieved higher levels of efficiency and accuracy in managing billing operations. This resulted in faster claim submissions, reduced errors, and improved
                revenue cycle management.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Compliance Adherence:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                UMS successfully maintained compliance with changing healthcare regulations. Through continuous monitoring and training, UMS avoided compliance issues, penalties, and legal complications, thereby safeguarding its reputation
                and ensuring client trust.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Increased Client Satisfaction:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The emphasis on client engagement, communication, and personalized attention led to increased client satisfaction. UMS addressed client concerns promptly, improved responsiveness, and tailored services to meet specific
                client needs, thereby enhancing long-term relationships.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                In conclusion, the case study of Ultra Medical Supplies (UMS) highlights the importance of addressing key challenges in the medical billing industry. Through the implementation of strategic solutions and proactive
                strategies, UMS successfully overcame the identified problems and achieved significant improvements in its operations.UMS's strategic scalability planning enabled the company to handle increased volumes of billing
                transactions without compromising service quality, facilitating business growth and expansion. The emphasis on continuous improvement and quality assurance allowed UMS to identify areas for enhancement, implement corrective
                measures, and optimize overall performance.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Strategic Planning',
            },
            {
                sol: 'Resource Allocation',
            },
            {
                sol: 'Strategic Planning',
            },
            {
                sol: 'Innovation and Agility'
            },
        ]
    },
    {
        id: "3", title: "Eblubox", path: `/case-study/:name`, src: eblubox,
        name: "Eblubox",
        metaTitle: 'Case Study: Eblubox Electronics - Empowering Customers with Exceptional Experience',
        metaDescription: 'Eblubox Electronics: Empowering Customers with Exceptional Experiences. Learn how they deliver superior gadgets and exceptional service.',
        tagline: 'Empowering Customers through Exceptional Experience',
        briefDescription: "Eblubox - is a renowned gadgets and electronics store that specializes in providing high-quality electronics for smartphones, tablets, and laptops. With a commitment to delivering superior products and exceptional customer service, Eblubox has established itself as a trusted destination for gadget enthusiasts. This case study sheds light on the success story of Eblubox, highlighting its customer-centric approach, industry challenges, and the strategies employed to overcome them.",
        img: image,
        imgTag: '',
        imgText: `Eblubox has emerged as a leading player in the electronics market by offering a comprehensive range of high-quality gadgets. The company's mission is to empower customers by providing them with cutting-edge technology and ensuring a seamless shopping experience.`,
        mainDescription: `
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Problem Identification: Increasing Competition and Customer Expectations
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            As the electronics market became increasingly competitive, Eblubox faced challenges in meeting rising customer expectations while differentiating itself from competitors. The company identified the need to enhance its customer
            experience to maintain a competitive edge and sustain business growth.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Solution and Strategy: Enhancing Customer Experience
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Ebulax implemented a range of strategies to enhance the customer experience and overcome the identified challenges:
        </span>
    </strong>
</p>
<p>
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8"><br /></strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            a. Product Curation:
        </span>
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Eblubox focused on curating a wide range of high-quality electronics, ensuring that customers have access to the latest and most reliable gadgets from renowned brands. The company constantly updated its product offerings to stay
            ahead of the market trends.
        </span>
    </strong>
</p>
<p>
    <strong style="font-weight: normal;" id="docs-internal-guid-460d1395-7fff-ba9e-b90b-73467dcca3d8">
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                b. Expert Product Knowledge:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Eblubox prioritized staff training to equip its team with in-depth knowledge about the products. Well-informed and knowledgeable staff were able to provide expert advice and guidance to customers, helping them make informed
                purchasing decisions.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                c. Personalized Customer Service:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The company emphasized personalized customer service, providing a friendly and welcoming environment for customers. Staff members were trained to actively listen to customer needs, address their concerns, and offer tailored
                recommendations based on individual preferences.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                d. Seamless Online Experience:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Eblubox invested in a user-friendly and intuitive website, allowing customers to browse and purchase products conveniently. The website provided detailed product information, customer reviews, and secure online transactions
                to enhance the overall shopping experience.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                e. After-Sales Support:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Eblubox prioritized after-sales support, ensuring that customers received assistance and guidance even after their purchase. This included prompt warranty services, technical support, and responsive communication channels
                for any post-purchase inquiries.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Development and Results:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Through the implementation of customer-centric strategies, Empower Electronics achieved significant positive outcomes:
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                a. Enhanced Customer Satisfaction:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The focus on product curation, expert product knowledge, personalized customer service, and seamless online experience contributed to increased customer satisfaction levels.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                b. Customer Loyalty and Advocacy:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Eblubox developed a loyal customer base that appreciated the superior products and exceptional service. Satisfied customers became brand advocates, recommending Eblubox to friends and family.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                c. Repeat Business and Increased Sales:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The enhanced customer experience resulted in a higher rate of repeat business, driving increased sales and revenue for Eblubox.
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                d. Competitive Differentiation:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                By providing a unique and personalized customer experience, Eblubox differentiated itself from competitors and solidified its position as a preferred destination for high-quality electronics.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Eblubox Electronics and Gadgets have successfully carved a niche in the gadgets and electronics market by prioritizing customer experience and offering high-quality products. Through strategic initiatives such as product
                curation, expert product knowledge, personalized customer service, seamless online experience, and after-sales support, Eblubox has overcome challenges, enhanced customer satisfaction, and achieved sustainable business
                growth. The company's commitment to empowering customers with cutting-edge technology and exceptional service has solidified its reputation as a go-to destination for gadget enthusiasts.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Diversification',
            },
            {
                sol: 'Communication Channels',
            },
            {
                sol: 'Development',
            },
            {
                sol: 'Marketing'
            },
        ]
    },
    {
        id: "4", title: "TRANS WORLDWIDE", path: `/case-study/:name`, src: trans,
        name: "TRANSWORLDWIDE",
        metaTitle: 'Transforming Global Cargo Shipping: The Success Story of Trans Worldwide Logistics',
        metaDescription: 'Transforming global cargo shipping with reliability and competitive pricing. Learn how Trans Worldwide Logistics achieved success in the industry.',
        tagline: 'Revolutionizing Global Cargo Shipping with Reliability and Competitive Pricing',
        briefDescription: "Trans Worldwide delivers not just your little boxes or fragile packages but also massive loads with the best and most reasonable shipping rates. Trans Worldwide offers global Ocean cargo, Air cargo, Rail cargo, and Road cargo with quick reliable cargo services around the world with ease. Trans Worldwide's goal is to provide customers with the very best services at competitive prices. We will always be available to provide quick and reliable shipping services.",
        img: image,
        imgTag: '',
        imgText: `Trans Worldwide is a global cargo shipping company that specializes in delivering a wide range of goods and commodities across the world. With a strong emphasis on reliability, competitive pricing, and exceptional customer service, Trans Worldwide has established itself as a trusted name in the logistics industry.
        Operating in both the USA and Canada allows Trans Worldwide to tap into two of the largest economies in the world. With its commitment to excellence and a customer-centric approach, the company continues to expand its operations, strengthen partnerships, and deliver exceptional shipping services to clients in both countries.`,
        mainDescription: `
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: 600;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Problem Identification: Streamlining Global Cargo Shipping for Quick and Reliable Delivery
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Trans Worldwide aims to deliver various types of cargo globally with competitive pricing. However, the company faces challenges in providing quick and reliable shipping services, especially for massive loads. This hampers
            customer satisfaction and limits their ability to expand their market share.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Solution and Strategies:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Improving Operational Efficiency:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Trans Worldwide Logistics focused on streamlining internal processes, optimizing cargo handling procedures, and enhancing logistics management. By implementing efficient workflows, they ensured smooth and timely delivery of all
            cargo sizes.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Enhancing Transportation Network:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The company strengthened partnerships with reliable ocean, air, rail, and road cargo carriers to expand their coverage and improve overall shipping reliability. By collaborating with trusted carriers, they increased their
            capacity to handle diverse shipping requirements.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Investing in Technology:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Trans Worldwide Logistics invested in advanced tracking systems, real-time communication platforms, and digital documentation solutions. These technological advancements enhanced transparency, provided customers with real-time
            shipment updates, and improved overall customer experience.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Adopting a Customer-Centric Approach:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Market research and gathering customer feedback became integral for understanding customer needs and preferences. Trans Worldwide Logistics tailored its services accordingly, providing personalized support and ensuring customer
            satisfaction.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Implementing Competitive Pricing Strategy:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The company continuously assessed market rates, negotiated with carriers, and optimized operational costs to offer the best shipping rates without compromising service quality. This enabled them to remain competitive in the
            market while providing high-quality shipping services.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Development and Results:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Trans Worldwide executed the above strategies to address the identified problems. They invested in advanced technology, established partnerships with reliable carriers, streamlined operations, and adopted a customer-centric
            approach. The company witnessed significant improvements in its cargo shipping services, including:
        </span>
    </strong>
</p>
<p>
    <strong style="font-weight: normal;" id="docs-internal-guid-d9310702-7fff-df6e-a47c-21dd15f8b45f">
        <br />
        <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: decimal;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Reduced transit times and improved reliability for both small and massive loads.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: decimal;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Enhanced visibility and tracking capabilities, allowing customers to monitor their shipments in real-time.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: decimal;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        They have increased customer satisfaction due to personalized support and improved communication channels.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: decimal;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Expansion of their transportation network, enabling them to reach more destinations worldwide.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: decimal;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Maintained competitive pricing while providing high-quality shipping services.
                    </span>
                </p>
            </li>
        </ol>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                As a result, Trans Worldwide experienced a substantial increase in customer base and revenue. They established a reputation as a reliable and efficient cargo shipping provider, further strengthening their market position.
                The company continues to innovate and adapt to evolving customer demands to maintain its success in the global shipping industry.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                In conclusion, Trans Worldwide Logistics has successfully transformed global cargo shipping by prioritizing reliability, competitive pricing, and customer satisfaction. Through streamlining operations, enhancing their
                transportation network, adopting advanced technology, and embracing a customer-centric approach, they have overcome challenges and established themselves as a trusted and efficient cargo shipping provider. With reduced
                transit times, improved tracking capabilities, personalized support, and an expanded network, Trans Worldwide Logistics has achieved significant growth, attracting a larger customer base and solidifying its position as a
                leader in the industry.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Strategic Partnerships and Alliances',
            },
            {
                sol: 'Market Development',
            },
            {
                sol: 'Product Diversification',
            },
            {
                sol: 'Improvement, Innovation and Sustainability'
            },
        ],
    },
    {
        id: "5", title: "Bits Gravity", path: `/case-study/:name`, src: bits,
        name: "BitsGravity",
        metaTitle: `Smart Home Security: Case Study on Bits Gravity's Strategies for Seamless Automation`,
        metaDescription: `Enhancing smart home security: A case study on Bits Gravity's strategies for seamless automation in the USA and Canada.`,
        tagline: 'Enhancing Smart Home Security',
        briefDescription: "Bits Gravity is a prominent smart home management company operating in the USA and Canada. They specialize in integrating and optimizing smart home devices, offering mobile control and advanced security measures. Their tailored smart home features prioritize protection against threats and emphasize energy efficiency. With affordable pricing starting at $1 a day, they aim to make smart home automation accessible to a broader range of customers.",
        img: image,
        imgTag: '',
        imgText: `Bits Gravity is a leading smart home management company operating in the USA and Canada. Their primary objective is to consolidate and streamline smart home devices, providing mobile control and enhanced security measures. They offer custom-made smart home features that aim to protect homes from threats while promoting energy efficiency. With their affordable pricing starting at just $1 a day, they strive to make smart home automation accessible to a wider audience.`,
        mainDescription: `
        <p>
    <strong style="font-weight: normal;" id="docs-internal-guid-1ad3fd62-7fff-3a2f-4701-622806664c23">
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Problem: Addressing Smart Home Security Challenges and Energy Efficiency Concerns
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The increasing adoption of smart home devices has led to a growing concern about home security vulnerabilities. Many homeowners face challenges in managing and securing their smart home systems effectively. Additionally,
                energy consumption remains a significant concern as inefficient device usage can lead to higher electricity bills. Bits Gravity needs to address these issues to maintain its competitive edge and provide a seamless smart home
                experience to its customers.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Strategy Solutions:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhancing Security Features:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Bits Gravity can invest in research and development to improve its security technologies. By partnering with cybersecurity experts, they can identify potential vulnerabilities and develop robust security measures to
                safeguard customers' homes. This could include implementing encryption protocols, advanced authentication methods, and continuous monitoring of smart home devices for potential threats.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                User-Friendly Interface:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Bits Gravity should focus on providing an intuitive and user-friendly mobile application interface. This would allow homeowners to easily navigate and control their smart home devices from their smartphones or tablets. By
                offering a seamless user experience, customers would be more likely to adopt and fully utilize the features of the Bits Gravity system.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Energy Management Solutions:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                To address energy consumption concerns, Bits Gravity can integrate energy management solutions into its platform. They can develop features that monitor and optimize the energy usage of connected devices. This could involve
                automatically adjusting device settings based on occupancy or time of day, providing recommendations for energy-efficient device usage, and enabling remote control to turn off devices when not in use.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Customer Education and Support:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Bits Gravity should invest in comprehensive customer education and support programs. This includes providing user manuals, video tutorials, and online resources to guide customers in setting up and troubleshooting their
                smart home devices. Additionally, a dedicated customer support team should be available to address any technical issues or concerns promptly, ensuring a positive customer experience.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Market Expansion and Partnerships:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                To further expand its reach, Bits Gravity can explore partnerships with home builders, real estate companies, and insurance providers. Collaborations with home builders would enable the integration of Bits Gravity's smart
                home management system into newly constructed homes, offering customers a seamless smart home experience from the start. Partnerships with insurance providers can lead to potential discounts for homeowners who have
                implemented Bits Gravity's security features, incentivizing adoption.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Development and Results:
            </span>
        </p>
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Bits Gravity effectively addressed smart home security and energy efficiency challenges through strategic development strategies.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Collaborated with cybersecurity experts to fortify security features with encryption protocols and advanced authentication methods.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Prioritized the development of an intuitive user-friendly interface for seamless navigation and control of smart home devices.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Integrated energy management solutions optimize device settings, reduce energy consumption, and enable cost savings for homeowners.
                    </span>
                </p>
            </li>
        </ul>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                By focusing on enhancing security features, providing a user-friendly interface, implementing energy management solutions, offering customer education and support, and exploring strategic partnerships, Bits Gravity can
                address the challenges faced by homeowners in managing and securing their smart home systems. This comprehensive approach will help them maintain their position as a leading smart home management company in the USA and
                Canada while delivering value to their customers.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Strategic Partnerships',
            },
            {
                sol: 'Technological Advancements',
            },
            {
                sol: 'Customer-Centric Approach',
            },
            {
                sol: 'Competitive Differentiation'
            },
        ],
    },
    {
        id: "6", title: "Milli Tellos Bakerys", path: `/case-study/:name`, src: milliTello,
        name: "MILLITELLOSBAKERYS",
        metaTitle: 'Case Study: Enhancing Customer Experience in a Milli Tellos Bakerys in Pakistan',
        metaDescription: 'Enhance customer experience & operations in a Milli Tellos Bakerys. Learn strategies for product innovation & streamlined processes in this case study.',
        tagline: 'Enhancing Customer Experience',
        briefDescription: "This case study focuses on a milli tellos bakery operating in Pakistan that offers a wide range of bakery items, including cakes, pastries, pizzas, lasagna, and ice creams. The company milli tellos bakery.pk aims to provide customers with a delightful experience by offering delicious, fresh, and high-quality bakery products.",
        img: image,
        imgTag: '',
        imgText: `The milli tellos bakery in Pakistan is a reputable and customer-centric bakery known for its high-quality and delicious bakery items. With a dedicated team of skilled pastry chefs and a commitment to using the finest ingredients, they have built a loyal customer base. Offering a wide range of bakery products, the milli tellos bakery aims to provide a delightful experience to customers seeking fresh and visually appealing treats.`,
        mainDescription: `
        <p>
    <strong style="font-weight: normal;" id="docs-internal-guid-e7a62efc-7fff-f892-7cc8-bf9b1eae1e87">
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Problem Identification:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                The milli tellos bakery identified two key challenges: Customer Experience and Operational Efficiency
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The milli tellos bakery in Pakistan identified two challenges: enhancing the customer experience by improving the taste, texture, and appearance of their bakery items, and optimizing operational efficiency to ensure fresh daily
                production, minimize waste, and streamline inventory and delivery processes.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Solution and Strategies:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Product Innovation and Quality Enhancement:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The milli tellos bakery focused on product innovation and quality enhancement to elevate the customer experience. They introduced a wider range of bakery items, including classic cakes, customized cakes, Swiss rolls, cheesecakes,
                classic cupcakes, brownies, and teatime celebration cakes. The milli tellos bakery invested in skilled pastry chefs and used high-quality ingredients to ensure the perfect balance of texture, appearance, and flavor in their
                products.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Streamlined Operations and Freshness Assurance:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                To address operational challenges, the milli tellos bakery implemented strategies to streamline its operations and ensure freshness:
            </span>
        </p>
        <br />
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Efficient Production Planning:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The shop implemented efficient production planning to align baking schedules with demand, minimizing waste and maximizing freshness.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Real-time Inventory Management:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                They adopted a digital inventory management system to track ingredient availability, monitor stock levels, and facilitate seamless reordering to avoid stockouts.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Just-in-Time Production:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                By adopting a just-in-time production approach, the milli tellos bakery baked and prepared items closer to their delivery time, ensuring maximum freshness for customers.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Delivery Optimization:
            </span>
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The shop optimized delivery routes and schedules to minimize transit time, ensuring that customers receive their bakery items promptly.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Customer Feedback and Continuous Improvement:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The milli tellos bakery actively sought customer feedback to understand their preferences and expectations better. They used customer feedback as a valuable source of insight for continuous improvement. By implementing changes based
                on customer suggestions, the milli tellos bakery aimed to enhance the overall customer experience and tailor their offerings to meet evolving demands.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Development and Results:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            ></span>
            <span style="background-color: transparent; color: rgb(67, 67, 67); font-family: Arial; font-size: 14pt; font-weight: 700; white-space-collapse: preserve;">Enhanced Customer Satisfaction:&nbsp;</span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The focus on product innovation, quality enhancement, and customer feedback resulted in increased customer satisfaction. The milli tellos bakery gained a reputation for offering delicious and visually appealing bakery items.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Improved Operational Efficiency:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Streamlining operations and implementing just-in-time production practices helped the milli tellos bakery minimize waste, optimize inventory, and ensure freshness, leading to improved operational efficiency.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Increased Customer Loyalty:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The milli tellos bakery's commitment to providing a delightful experience and continuously improving its offerings fostered customer loyalty. Satisfied customers became repeat customers and advocates, spreading positive
                word-of-mouth and attracting new business.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                By addressing the challenges related to customer experience and operational efficiency, the milli tellos bakery in Pakistan successfully enhanced its offerings and improved overall business performance. Through product innovation,
                quality enhancement, streamlined operations, and customer feedback-driven improvements, they were able to exceed customer expectations, ensure freshness, and foster customer loyalty. The milli tellos bakery's commitment to
                delivering a wide range of delightful bakery items continues to position them as a preferred choice for customers seeking the best sweet treats in Pakistan.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Market Analysis and Customer Segmentation',
            },
            {
                sol: 'Differentiation and Unique Value Proposition',
            },
            {
                sol: 'Operational Excellence and Efficiency',
            },
            {
                sol: 'Continuous Innovation and Product Development'
            },
        ],
    },
    {
        id: "7", title: "The Hidden Valleys", path: `/case-study/:name`, src: hidden,
        name: "TheHiddenValleys",
        metaTitle: `Hidden Valley: Embracing Diversity and Showcasing Wisconsin's Beauty`,
        metaDescription: `Discover how Hidden Valley promotes Wisconsin's attractions, boosts tourism, and engages communities through integrated marketing and collaborations.`,
        tagline: `Embracing Diversity and Showcasing Wisconsin's Beauty`,
        briefDescription: "Hidden Valley is a comprehensive project that aims to highlight the diversity and natural beauty of Wisconsin, a Mid-Western U.S. state known for its stunning coastlines, lush forests, and vibrant agricultural landscapes. This case study examines the challenges faced by Hidden Valleys in promoting cultural attractions, outdoor recreation, events, agriculture, and history across nine counties. We will also explore the solutions implemented to showcase the hidden gems of Wisconsin and attract visitors to the region.",
        img: image,
        imgTag: '',
        imgText: `Hidden Valleys is a project aimed at showcasing the diversity and beauty of Wisconsin, a Mid-Western U.S. state known for its coastlines, forests, and farms. It focuses on promoting cultural attractions, outdoor recreation, events, agriculture, and history across nine counties.`,
        mainDescription: `
        <p><br /></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Problem Identification: Limited Awareness and Fragmented Information Hindering Growth
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The main challenges faced by Hidden Valleys were the lack of awareness about the region's attractions and the fragmented information available. Limited knowledge about the hidden gems of Wisconsin and difficulty in accessing
            comprehensive information hindered the region's ability to attract visitors and promote economic growth.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Solutions and Strategies:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Integrated Marketing Strategy:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Hidden Valley implemented a comprehensive marketing strategy to raise awareness about the region's diverse attractions. This included targeted digital marketing campaigns, social media promotions, collaboration with travel
            bloggers and influencers, and partnerships with local businesses and tourism organizations. By leveraging various marketing channels, Hidden Valleys was able to reach a wider audience and generate interest in visiting the
            region.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Centralized Information Hub:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            To address the issue of fragmented information, Hidden Valley developed a centralized online platform that served as a one-stop resource for visitors. The website provided detailed information about cultural attractions, outdoor
            activities, upcoming events, local farms, historical landmarks, and more. It also featured interactive maps, travel itineraries, and user-generated content to enhance the visitor experience and facilitate trip planning.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Collaborations and Partnerships:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Hidden Valley actively collaborated with local businesses, event organizers, farmers, and historical societies to create engaging experiences for visitors. This included organizing joint promotional events, offering package
            deals combining multiple attractions, and showcasing local products and services. By fostering partnerships, Hidden Valleys created a network of support and amplified its reach within the community and beyond.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Development and Results:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The implementation of these solutions yielded significant results for Hidden Valley:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Increased Awareness:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Through targeted marketing efforts, Hidden Valley successfully increased awareness of the region's beauty and diversity. More people became aware of the cultural attractions, outdoor recreation opportunities, and agricultural
            experiences available in Wisconsin's Hidden Valleys.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Boost in Tourism:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The centralized information hub and collaborative initiatives led to an increase in tourist footfall. Visitors were able to access comprehensive and reliable information, making it easier for them to plan their trips and explore
            the hidden gems of Wisconsin. This, in turn, contributed to the growth of the local economy and supported businesses within the region.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Community Engagement:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The collaborative approach fostered a sense of community engagement and pride among the residents of Hidden Valley. Local businesses, organizations, and individuals actively participated in promoting the region, leading to a
            stronger sense of identity and unity.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Economic Growth:
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The efforts of Hidden Valley in promoting the region's attractions and providing comprehensive information resulted in significant economic growth. The increased tourism and visitor spending contributed to the local economy,
            benefiting businesses, and entrepreneurs, and supporting job creation within the nine counties of Wisconsin.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Conclusion:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-32106904-7fff-d692-8c17-6ade16e12a66">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            The Hidden Valley project successfully overcame the challenges of limited awareness and fragmented information to showcase the diversity and beauty of Wisconsin's nine counties. Through integrated marketing strategies, a
            centralized information hub, and strong collaborations, Hidden Valleys attracted more visitors, boosted tourism, and fostered community engagement. By embracing diversity and showcasing the hidden treasures of the region, Hidden
            Valleys has become a must-visit destination for those seeking cultural enrichment, outdoor adventures, and a glimpse into Wisconsin's rich history.
        </span>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Integrated Marketing Approach',
            },
            {
                sol: 'Centralized Information Hub',
            },
            {
                sol: 'Collaborations and Partnerships',
            },
            {
                sol: 'Sustainability and Conservation Focus'
            },
        ],
    },
    {
        id: "8", title: "Whitegloves Concierge", path: `/case-study/:name`, src: white,
        name: "WhiteglovesConcierge",
        metaTitle: 'Enhancing Customer Satisfaction and Efficiency',
        metaDescription: 'Learn how White Gloves Concierge improved customer satisfaction and operational efficiency through strategic management and technology integration.',
        tagline: `Transforming Customer Experience`,
        briefDescription: "White Gloves Concierge offers services like Health care, Utility Setup Specialist, Personal Support, Customer Service, E-commerce operation, and Executive Admin support. Not cheap however the remarkable and usually dependable offerings. Friendly user care offerings have 24 hours. Skilled and professional offerings to raise your house or enterprise to the best level. Their aim is to offer their customers pleasant domestic offerings at a reasonable price.",
        img: image,
        imgTag: '',
        imgText: `White Gloves Concierge is a service provider offering a wide range of services including healthcare, utility setup, personal support, customer service, e-commerce operation, and executive admin support. Known for their remarkable and reliable offerings, White Gloves Concierge takes pride in delivering skilled and professional services that elevate homes and businesses to the highest level. Their mission is to provide customers with quality home services at an affordable price.`,
        mainDescription: `
        <p>
    <strong style="font-weight: normal;" id="docs-internal-guid-06598dfa-7fff-0535-342f-8a16d1009613">
        <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Problem Identification: Enhancing Customer Acquisition, Retention, and Satisfaction for White Gloves Concierge
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Despite offering exceptional and dependable services, White Gloves Concierge faces a problem in attracting and retaining customers. The main challenges include the perception of high prices, the need to enhance customer care
                services, and the need to demonstrate the value proposition of their offerings. The company aims to address these challenges to improve customer acquisition, retention, and overall satisfaction.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Solution Strategies:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhancing Customer Service:
            </span>
        </h3>
        <br />
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Implement a comprehensive customer service training program to ensure that all employees are equipped with the necessary skills to deliver exceptional service.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Establish clear communication channels and response protocols to address customer inquiries, concerns, and complaints in a timely and satisfactory manner.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Foster a customer-centric culture throughout the organization, emphasizing empathy, active listening, and personalized service.
                    </span>
                </p>
            </li>
        </ul>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Streamlining Operations and Processes:
            </span>
        </h3>
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Conduct a thorough review of internal processes and identify areas for optimization and efficiency improvement.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Leverage technology solutions, such as CRM systems and automation tools, to streamline tasks, reduce manual errors, and enhance productivity.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Implement standardized procedures and workflows to ensure consistency and quality in service delivery across different departments and service offerings.
                    </span>
                </p>
            </li>
        </ul>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Personalization and Customization:
            </span>
        </h3>
        <br />
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Develop a deep understanding of customer preferences, needs, and expectations through data analysis and customer feedback.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Tailor services and offerings to meet individual customer requirements, providing a personalized and customized experience.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Utilize customer segmentation strategies to deliver targeted marketing campaigns, promotions, and recommendations.
                    </span>
                </p>
            </li>
        </ul>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Proactive Communication and Engagement:
            </span>
        </h3>
        <br />
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Establish regular communication channels to keep customers informed about new services, updates, and promotions.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Implement proactive outreach initiatives, such as personalized emails, newsletters, and social media engagement, to maintain ongoing customer engagement.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Seek feedback from customers through surveys, focus groups, and feedback mechanisms to continuously improve services and address evolving needs.
                    </span>
                </p>
            </li>
        </ul>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Continuous Improvement and Innovation:
            </span>
        </h3>
        <br />
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Encourage a culture of continuous improvement, where employees are empowered to suggest ideas and implement innovative solutions.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Regularly review and analyze customer feedback, market trends, and industry best practices to identify opportunities for innovation and service enhancement.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Invest in research and development to stay ahead of the competition and introduce new services or service features that align with customer needs and market demands.
                    </span>
                </p>
            </li>
        </ul>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Development and Results:
            </span>
        </p>
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        The implementation of the customer service training program resulted in improved skills and competencies among employees, leading to enhanced service delivery and customer satisfaction.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Streamlining operations and processes helped optimize resource utilization, reduce inefficiencies, and improve overall operational efficiency.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Personalization and customization efforts resulted in a more tailored and personalized service experience for customers, increasing their satisfaction and loyalty.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Proactive communication and engagement initiatives helped build stronger relationships with customers and fostered a sense of trust and loyalty.
                    </span>
                </p>
            </li>
        </ul>
        <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Customer satisfaction levels significantly improved, as evidenced by positive feedback, higher customer ratings, and increased customer retention rates.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        The company experienced an increase in repeat business and referrals from satisfied customers, indicating strong customer advocacy and word-of-mouth marketing.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        Operational efficiency improvements led to cost savings and increased profitability for the organization.
                    </span>
                </p>
            </li>
            <li
                dir="ltr"
                style="
                    list-style-type: disc;
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    font-size: 11pt;
                "
                aria-level="1"
            >
                <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;" role="presentation">
                    <span
                        style="
                            font-family: Arial;
                            color: rgb(0, 0, 0);
                            background-color: transparent;
                            font-weight: 400;
                            font-style: normal;
                            font-variant: normal;
                            text-decoration: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            font-size: 11pt;
                        "
                    >
                        White Gloves Concierge gained a competitive edge in the market by delivering exceptional and personalized services, attracting new customers, and expanding its customer base.
                    </span>
                </p>
            </li>
        </ul>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The case study of White Gloves Concierge highlights the successful implementation of customer-centric strategies, resulting in improved customer satisfaction, loyalty, and business profitability. Through addressing customer
                service issues, streamlining operations, and delivering personalized services, the company achieved increased efficiency, cost savings, and strengthened customer relationships. These efforts positioned White Gloves Concierge
                as a reliable and high-quality service provider, leading to a competitive advantage and long-term growth.<br />
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Process Optimization',
            },
            {
                sol: 'Talent Management',
            },
            {
                sol: 'Personalization and Customization',
            },
            {
                sol: 'Technology Integration'
            },
        ],
    },
    {
        id: "9", title: "Curvy Egg", path: `/case-study/:name`, src: curvy,
        name: "CurvyEgg",
        metaTitle: 'Curvy Egg Case Study: Strategic Solutions for Enhanced Customer Experience and Growth',
        metaDescription: 'Explore how Curvy Egg, a chatbot company, implemented strategic solutions to improve customer experience and leverage technology for personalized recommendations.',
        tagline: `Transforming Customer Experience`,
        briefDescription: "It's a Chatbot company not just limited to 24/7 availability. It improves internal communication and processes within the company. Curvy Egg is a frictionless platform for presenting users with algorithm-driven, personalized recommendations of a company's new products and services. It removes the gap in communication between the Customer and Vendor. Always available to help the customers with quick customer service.",
        img: image,
        imgTag: '',
        imgText: `Curvy Egg is a Chatbot company that goes beyond 24/7 availability. They specialize in enhancing internal communication and streamlining processes within organizations. Their frictionless platform utilizes algorithm-driven, personalized recommendations to present users with a company's latest products and services. By bridging the communication gap between customers and vendors, Curvy Egg ensures quick and efficient customer service at all times.`,
        mainDescription: `
        <p>
    <strong style="font-weight: normal;" id="docs-internal-guid-13bbfbff-7fff-8c87-f001-0c249ca71a2b">
        <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Problem Identification: Challenges Faced by Curvy Egg in Customer Engagement, Communication Gap, Scalability, and Adaptability
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg, a chatbot company, faces challenges in limited customer engagement, a communication gap between customers and vendors, scalability and integration issues, and inadequate adaptability and learning capabilities of
                their chatbot solution.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Strategy and Solutions:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Implementing a Robust Chatbot System:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg developed and deployed a highly efficient chatbot system to improve internal communication and streamline processes within the company. The chatbot facilitated quick and seamless communication between team members,
                enabling faster decision-making and reducing response times.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Personalized Recommendation Engine:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg integrated an algorithm-driven recommendation engine into its platform, allowing users to receive personalized product and service recommendations based on their preferences and past interactions. This strategy
                enhanced customer engagement and improved the overall user experience.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Continuous Improvement and Feedback Loop:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg established a process for collecting feedback from both customers and employees. They actively sought suggestions for improvement and implemented iterative changes to enhance their chatbot system and recommendation
                engine. This approach ensured that the company stayed responsive to evolving needs and preferences.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Training and Skill Development:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg invested in training programs to enhance the skills of their employees, particularly in areas related to customer service and communication. By equipping its team members with the necessary knowledge and expertise,
                the company aimed to provide exceptional customer support and build strong relationships with clients.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Integration and Seamless Communication:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg focused on integrating its chatbot system with other internal tools and platforms, such as CRM systems and helpdesk software. This integration ensured smooth communication across different departments and
                streamlined workflows, leading to improved efficiency and customer satisfaction.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Continuous Monitoring and Analysis:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Curvy Egg implemented a robust system for monitoring chatbot performance, user interactions, and customer satisfaction metrics. This data-driven approach allowed the company to identify bottlenecks, optimize its chatbot
                system, and make data-backed decisions to improve overall performance.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Developments and Results:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Improved Internal Communication:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The implementation of the chatbot system led to significant improvements in internal communication within Curvy Egg. Team members were able to collaborate more effectively, share information efficiently, and make faster
                decisions, resulting in streamlined processes and improved productivity.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhanced Customer Engagement:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The personalized recommendation engine introduced by Curvy Egg proved to be a game-changer for customer engagement. Users received tailored recommendations based on their preferences, increasing their satisfaction and
                likelihood of making a purchase. This led to higher customer retention rates and increased sales.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Increased Efficiency and Productivity:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The integration of the chatbot system with other internal tools and platforms improved workflow efficiency and reduced manual work. Employees could focus on more complex tasks, while routine inquiries and support requests
                were handled by the chatbot. This resulted in improved productivity and allowed employees to allocate their time more effectively.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Positive Customer Feedback:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Customers appreciated the quick and reliable customer service provided by Curvy Egg through the chatbot system. The availability of assistance 24/7 and the ability to receive prompt responses to their queries contributed to
                a positive customer experience and satisfaction.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                In summary, the case study of Curvy Egg highlights the successful implementation of a chatbot system and personalized recommendation engine, resulting in improved internal communication, customer engagement, and business
                growth. These strategic solutions have streamlined processes, enhanced customer experiences, and positioned Curvy Egg as a leader in providing frictionless communication and personalized recommendations.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Talent Development and Retention',
            },
            {
                sol: 'Focus on Customer Experience',
            },
            {
                sol: 'Investment in Technology Infrastructure',
            },
            {
                sol: 'Adoption of Agile Project Management'
            },
        ],
    },
    {
        id: "10", title: "24/7 Home Services", path: `/case-study/:name`, src: home,
        name: "24-7HomeServices",
        metaTitle: '24/7 Home Services: Enhancing Service Delivery and Driving Customer Satisfaction',
        metaDescription: 'Explore how 24/7 Home Services implemented effective strategy management to elevate service delivery, optimize processes, and achieve high customer satisfaction.',
        tagline: `Transforming Service Excellence`,
        briefDescription: "24/7 Home Services offers Residential Home Security, Solar, TV, and Internet Services. They bring the best services to the customers in the comfort of their homes. Not cheap but the great and always reliable services. Friendly customer care services are available 24 hours. Skilled and professional services elevate your home or business to the highest level. Their goal is to provide their clients with the best home services ever at a competitive price.",
        img: image,
        imgTag: '',
        imgText: `24/7 Home Services is a leading provider of residential home security, solar, TV, and internet services operating in the USA. With a commitment to delivering exceptional services, they bring convenience, reliability, and peace of mind to customers across the country. Their 24-hour customer care services ensure that clients receive prompt assistance and support whenever needed. Backed by a team of skilled professionals, 24/7 Home Services aims to enhance homes and businesses throughout the USA, providing top-quality services tailored to meet individual needs.`,
        mainDescription: `
        <p>
    <strong style="font-weight: normal;" id="docs-internal-guid-dde1533f-7fff-3100-0dce-b0b9a19f8228">
        <p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Problem Identification: Declining Customer Satisfaction and Retention Rates for 24/7 Home Services
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The problem identified in this case study is the declining customer satisfaction and retention rates for 24/7 Home Services. Inadequate customer support, billing discrepancies, and inconsistent service quality have resulted
                in dissatisfaction among customers. Addressing these issues is crucial for the company to improve customer satisfaction, retain existing clients, and attract new ones.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Strategy and Solution:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhancing Customer Service:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The company focused on improving its customer service by increasing staffing levels, implementing comprehensive training programs for representatives, and reducing response times. This ensured that customers' queries and
                concerns were addressed promptly and effectively, leading to higher satisfaction levels.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Streamlining Service Processes:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                24/7 Home Services identified and streamlined its service processes to eliminate inefficiencies and delays. This involved optimizing installation and maintenance procedures, ensuring timely and accurate billing, and
                providing transparent and easily understandable service contracts to customers.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Offering Personalized Services:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                24/7 Home Services introduced personalized service offerings tailored to individual customer needs. This included customized security solutions, flexible solar plans, and personalized TV and internet packages. By providing
                personalized services, the company aimed to enhance customer satisfaction and meet diverse customer preferences.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Strengthening Communication Channels:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The company improved its communication channels to foster better engagement with customers. This involved implementing a user-friendly online portal for service management, offering mobile apps for easy access to account
                information, and utilizing proactive communication methods such as email notifications and SMS alerts for service updates and promotions.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Implementing Customer Loyalty Programs:
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                To enhance customer retention, 24/7 Home Services introduced customer loyalty programs. These programs offered exclusive benefits, discounts, and rewards to long-term customers, incentivizing them to stay with the company
                and further enhancing their satisfaction and loyalty.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Implementing Quality Assurance Measures:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The company established rigorous quality assurance measures to monitor service delivery and ensure consistent service quality. This included regular inspections, customer feedback mechanisms, and performance evaluations to
                identify areas for improvement and take proactive actions.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Developments and Results:
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Increased Customer Satisfaction:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The company experienced a notable improvement in customer satisfaction levels as a result of enhanced customer service, streamlined processes, and personalized offerings. Customers reported greater convenience, prompt issue
                resolution, and higher overall satisfaction with the services provided by 24/7 Home Services.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Improved Customer Retention:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The focus on customer-centric solutions and loyalty programs resulted in improved customer retention rates. Existing customers were more likely to continue their subscription with 24/7 Home Services, leading to increased
                recurring revenue and a stronger customer base.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Enhanced Service Efficiency:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                The streamlining of service processes and the introduction of quality assurance measures led to improved service efficiency. This resulted in faster installation and maintenance processes, accurate billing, and reduced
                service disruptions, ultimately providing a smoother and more reliable experience for customers.
            </span>
        </p>
        <h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(67, 67, 67);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 14pt;
                "
            >
                Positive Word-of-Mouth and Referrals:&nbsp;
            </span>
        </h3>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                Satisfied customers became advocates for 24/7 Home Services, spreading positive word-of-mouth and referring the company to their friends, family, and colleagues. This organic marketing contributed to an increase in new
                customer acquisition and further bolstered the company's reputation in the market.
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 16pt;
                "
            >
                Conclusion:
            </span>
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
            <span
                style="
                    font-family: Arial;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    font-size: 11pt;
                "
            >
                In summary, the case study of 24/7 Home Services demonstrates the effectiveness of implementing strategic solutions to address customer satisfaction and service efficiency in the residential home services industry. By
                focusing on customer-centric approaches, the company achieved improved customer satisfaction, enhanced service efficiency, and gained a competitive edge in the market. The case study highlights the importance of
                understanding customer needs and delivering exceptional service experiences to drive success in the home services sector.
            </span>
        </p>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Performance Measurement',
            },
            {
                sol: 'Effective Communication',
            },
            {
                sol: 'Innovation and Differentiation',
            },
            {
                sol: 'Process Optimization'
            },
        ],
    },
    {
        id: "11", title: "Cadi Deals", path: `/case-study/:name`, src: cadi,
        name: "CadiDeals",
        metaTitle: 'CADI Deals: Overcoming Challenges and Driving Success in the E-commerce Industry.',
        metaDescription: 'Discover how CADI Deals navigated intense competition, optimized supply chain operations, and enhanced the user experience to achieve success in the e-commerce industry.',
        tagline: `Overcoming Challenges and Driving Success in the E-commerce Industry`,
        briefDescription: "CADI Deals is an emerging player in the e-commerce industry, focused on providing customers with a seamless online shopping experience. As a digital marketplace, CADI Deals offers a wide range of products from various categories, including electronics, fashion, home decor, and more. Their commitment to customer satisfaction, competitive pricing, and efficient delivery has set them apart in the highly competitive e-commerce landscape.",
        img: image,
        imgTag: '',
        imgText: `CADI Deals is a rapidly growing e-commerce company operating in the USA, Canada, and UK. With a focus on customer satisfaction, they offer a wide range of products at competitive prices. Their user-friendly platform and efficient delivery have made them a trusted destination for online shopping. CADI Deals continues to expand its presence and innovate in the ever-evolving e-commerce industry.`,
        mainDescription: `
        <p><br /></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Problem Identification: Impact of Inefficiencies in the Supply Chain
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals faced the complexity of managing inventory levels, coordinating order fulfillment processes, and coordinating logistics operations to meet customer expectations. Inefficiencies in the supply chain can lead to issues
            such as stockouts, delayed deliveries, and dissatisfied customers.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Strategy and Solutions:
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Differentiation and Trust Building
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Solution: CADI Deals focused on curating a unique product selection, emphasizing quality, and offering competitive pricing. They implemented transparent customer reviews and ratings to build trust and credibility. Additionally,
            they provided exceptional customer service to ensure a positive shopping experience and foster customer loyalty.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Supply Chain Optimization
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals leveraged advanced inventory management systems to streamline their inventory levels. They implemented real-time tracking and forecasting tools to ensure optimal stock levels and minimize stockouts. They partnered
            with reliable suppliers and logistics providers to improve coordination and reduce delivery delays.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            User Experience Enhancement
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals employed a pricing strategy that balanced profitability with competitive pricing. They regularly analyzed market trends and competitor pricing to offer competitive deals and discounts. Special promotions, flash sales,
            and loyalty programs were introduced to incentivize repeat purchases and drive customer engagement.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Continuous Improvement and Data Analytics
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Solution: CADI Deals established a culture of continuous improvement, leveraging data analytics to gain insights into customer preferences, purchase patterns, and browsing behavior. This data-driven approach helped them make
            informed decisions, identify opportunities for improvement, and refine their strategies to better meet customer expectations.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Development and Results:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            They continuously expanded their product offerings, partnering with additional suppliers to diversify their inventory and cater to a wider range of customer preferences.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Investments were made in advanced technology and analytics to gain deeper insights into customer behavior, allowing for personalized recommendations and targeted marketing.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals prioritized continuous improvement, actively seeking and incorporating customer feedback to enhance their platform's usability, functionality, and overall user experience.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Increased Market Share:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            By differentiating themselves through product selection, pricing, and customer service, CADI Deals successfully captured a larger market share, standing out among competitors in the e-commerce industry.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Improved Customer Trust and Loyalty:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Their commitment to transparency, quality products, and exceptional customer service helped build trust and foster customer loyalty. Positive reviews, ratings, and repeat purchases indicated a strong customer base.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Enhanced Operational Efficiency:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            Through supply chain optimization, CADI Deals achieved improved inventory management, reduced stockouts, and enhanced order fulfillment processes. This resulted in faster and more reliable product deliveries, leading to higher
            customer satisfaction.
        </span>
    </strong>
</p>
<h3 dir="ltr" style="line-height: 1.38; margin-top: 16pt; margin-bottom: 4pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="
                font-family: Arial;
                color: rgb(67, 67, 67);
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
                font-size: 14pt;
            "
        >
            Business Expansion:&nbsp;
        </span>
    </strong>
</h3>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals' success allowed them to expand their operations geographically and diversify their product offerings. They extended their reach to new regions, such as Canada and the United Kingdom, capitalizing on new market
            opportunities and further fueling their growth.
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 18pt; margin-bottom: 6pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 16pt;"
        >
            Conclusion:
        </span>
    </strong>
</p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;">
    <strong style="font-weight: normal;" id="docs-internal-guid-3ce8ca75-7fff-52a0-ef8f-6a39d7e301c3">
        <span
            style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; font-size: 11pt;"
        >
            CADI Deals has successfully navigated the competitive e-commerce landscape by implementing strategic initiatives and prioritizing customer-centricity. Their focus on differentiation, supply chain optimization, user experience
            enhancement, and trust-building has propelled their growth and market presence. With a strong reputation, efficient operations, and a seamless platform, CADI Deals has earned customer trust and loyalty. As they continue to
            innovate and adapt to changing market dynamics, CADI Deals is poised for sustained success in the ever-evolving world of e-commerce.
        </span>
    </strong>
</p>
        `,
        bgImge: bgbulax,
        solutions: [
            {
                sol: 'Data-driven Decision Making',
            },
            {
                sol: 'Customer Engagement Strategy',
            },
            {
                sol: 'Operational Efficiency Strategy',
            },
            {
                sol: 'Inventory Optimization Strategy'
            },
        ],
    },
    // {
    //     id: "11", title: "ALM", path: "/", src: alm,
    //     name: "ALM",
    //     briefDescription: "Delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets.",
    //     bgImge: bgausss,
    //     img: almimage,
    // },
    // {
    //     id: "12", title: "Hanged", path: "/", src: hanged,
    //     name: "Hanged",
    //     briefDescription: "Electronic Arts is a leading global interactive entertainment software company. EA delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets. WebEnertia worked with EA to create a unique event registration and management system.",
    //     bgImge: bghanged,
    //     img: hangedimage,
    // },
    // {
    //     id: "13", title: "Labbaiyk", path: "/", src: labbaiyk,
    //     name: "Labbaiyk",
    //     briefDescription: "Electronic Arts is a leading global interactive entertainment software company. EA delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets. WebEnertia worked with EA to create a unique event registration and management system.",
    //     bgImge: bglabbaiyk,
    //     img: labbaiykimage,
    // },
    // {
    //     id: "14", title: "Mega Transfer", path: "/", src: mega,
    //     name: "MegaTransfer",
    //     briefDescription: "With the motive of providing a budget friendly, user pleasant and sort of top notch service, Mega Tranfers pretty Limited typically was born in a delicate way. Serving nearly a thousand clients, Mega Tranfers definitely Limited more often than not earned the love of typically many more and counting. Over the years we grew to be the pioneer of Cab services earning a lot of love and popularity. Mega Tranfers Limited for all intents and functions is widely recognized for its reliability, time sensitiveness and flexibility in a refined way.",
    //     bgImge: bgmega,
    //     img: megaimage,
    // },
    // {
    //     id: "15", title: "PHP Travels", path: "/", src: php,
    //     name: "PHPTravels",
    //     briefDescription: "PHPTRAVELS is an online reservations answer that acts as your work of journey platform in a fantastically major way. It features as a aggregate of each booking and reporting packages and can work on any machine in a very massive way. With it, you can surely make pages for content material and tour guides in a refined way. The bendy solution which can especially be used on a number reservation services .",
    //     bgImge: bgbulax,
    //     img: image,
    // },
    // {
    //     id: "16", title: "WiZip Tv", path: "/", src: wizi,
    //     name: "WiziTv",
    //     briefDescription: "Electronic Arts is a leading global interactive entertainment software company. EA delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets. WebEnertia worked with EA to create a unique event registration and management system.",
    //     bgImge: bgwizi,
    //     img: wiziimage,
    // },
    // {
    //     id: "17", title: "Spectrum", path: "/", src: spectrum,
    //     name: "Spectrum",
    //     briefDescription: "In Spectrum.com Our TV Subscription  lets you certainly watch basically your commonly favorite content on typically more than one devices. You can watch your preferred movies, TV shows, originals, reality TV, or even sports activities besides everybody clearly nagging you to change the channel, which genuinely is fairly significant. Available to Watch; Anytime, Anywhere. No Wasted Hours of Programming, which especially is fairly significant. Content in the Hands of Creators in a certainly primary way, which is quite significant. User Interaction in a refined way, which really is pretty significant.",
    //     bgImge: bgspectrum,
    //     img: spectrumimage,
    // },
    // {
    //     id: "18", title: "Sharahu", path: "/", src: sharahu,
    //     name: "Sharahu",
    //     briefDescription: "Electronic Arts is a leading global interactive entertainment software company. EA delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets. WebEnertia worked with EA to create a unique event registration and management system.",
    //     bgImge: bgsharahu,
    //     img: sharahuimage,
    // },
    // {
    //     id: "19", title: "Digi Media", path: "/", src: digi,
    //     name: "DigiMedia",
    //     briefDescription: "Digital advertising if truth be told is the new shape of marketing. It particularly makes use of the net and digital applied sciences eg digital media, the internet, mobile phones, digital media, etc. Lift your business to new heights with our digital marketing services. Better ideas for fast growth. Advertise, analyze, and optimize! We do it all for you.",
    //     bgImge: bgdigi,
    //     img: digiimage,
    // },
    // {
    //     id: "20", title: "PIDM", path: "/", src: pidm,
    //     name: "PIDM",
    //     briefDescription: "Electronic Arts is a leading global interactive entertainment software company. EA delivers games, content and online services for Internet-connected consoles, personal computers, mobile phones and tablets. WebEnertia worked with EA to create a unique event registration and management system.",
    //     bgImge: bgpidm,
    //     img: pidmimage,
    // },
    // {
    //     id: "21", title: "Phuppi", path: "/", src: phuppi,
    //     name: "Phuppi",
    //     briefDescription: "Best offers making communication easier with us. Widest choices and best offers. Moreover several brands under one roof. Best services in the town. Visit us to get the gadgets of next generation.",
    //     bgImge: bgphuppi,
    //     img: phuppiimage,
    // },
];
