import { lazy } from "react";
import Layout from "../layout/Layout";
import NotFound from "../components/notFound/notFound";

const AllBlogs = lazy(() => import("../components/blogs/AllBlogs"))
const Careers = lazy(() => import("../components/careers/Careers"));
const Program = lazy(() => import("../components/careers/Program/Program"));
const CaseStudies = lazy(() => import("../components/caseStudies/CaseStudies"));
const Case = lazy(() => import("../components/caseStudies/cases/Case"));
const ContactForm = lazy(() => import("../components/contactForm/ContactForm"));
const ContactUs = lazy(() => import("../components/contactUs/ContactUs"));
const Home = lazy(() => import("../components/Home/home"));
const StartProject = lazy(() => import("../components/startProject/StartProject"));
const TeamConditions = lazy(() => import("../components/termCondition/TermsConditions"));
const Blog = lazy(() => import("../components/blogs/Blog/Blog"));

export const Routes = [
    { path: "/", element: <Layout><Home /></Layout> },
    { path: "/case-studies", element: <Layout><CaseStudies /></Layout> },
    { path: "/careers", element: <Layout><Careers /></Layout> },
    { path: "/blogs", element: <Layout><AllBlogs /></Layout> },
    { path: "/blogs/:searchurl", element: <Layout><Blog /></Layout> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/start-project", element: <Layout><StartProject /></Layout> },
    { path: "/case-study/:name", element: <Layout><Case /></Layout> },
    { path: "/careers/:path", element: <Layout><Program /></Layout> },
    { path: "/career-form", element: <Layout><ContactForm /></Layout> },
    { path: "/terms-and-privacy", element: <Layout><TeamConditions /></Layout> },
    { path: "*", element: <Layout><NotFound /></Layout> }
];


export const noIndexRoutes = [
    "*",
    "/blogs/:searchurl",
    "/case-study/:name",
    "/terms-and-privacy",
];