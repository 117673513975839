import { Projects } from "../constants/Project";
import { Routes, noIndexRoutes } from "./Routes";
import axios from "axios"; 

const hostname = "https://villaextechnologies.com";

export function GenerateSitemap() {
  const currentDate = new Date().toISOString().split("T")[0];
  let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
  xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

  for (const route of Routes) {
    if (noIndexRoutes.includes(route.path)) {
        continue; 
      }
    xml += "<url>\n";
    xml += `<loc>${hostname}${route.path}</loc>\n`;
    xml += `<lastmod>${currentDate}</lastmod>\n`; 
    xml += "<priority>0.8</priority>\n"; 
    xml += "</url>\n";
  }
  for (const route of Projects) {
    xml += "<url>\n";
    xml += `<loc>${hostname}/case-study/${route.name} </loc>\n`;
    xml += `<lastmod>${currentDate}</lastmod>\n`; 
    xml += "<priority>0.8</priority>\n"; 
    xml += "</url>\n";
  }


  axios
    .get("https://villaex-cms.herokuapp.com/blog?website=villaextechnologies")
    .then((response) => {
      const blogs = response?.data?.blogs;

      for (const blog of blogs) {
        xml += "<url>\n";
        xml += `<loc>${hostname}/blogs/${blog?.searchurl}</loc>\n`;
        xml += `<lastmod>${currentDate}</lastmod>\n`; 
        xml += "<priority>0.8</priority>\n"; 
        xml += "</url>\n";
      }

      xml += "</urlset>";

    })
    .catch((err) => {
      console.error("Error fetching blog data:", err);
    });
}
