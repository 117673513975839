import React, { useEffect, useState } from 'react'
import { HeaderOption } from '../../constants/Header'
import logo from "../../assets/11/Black.png"
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';

const Header = () => {
  const [bgScroll, setBgScroll] = useState(false);
  const [mobileMenu, setMobileMenu]= useState(false);
  // const location = useLocation();
  const isHomeRoute = window.location.pathname === "/" ;



  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setBgScroll(window.pageYOffset > 300)
      );
    }
  }, []);
  const handleClick=()=>{
    setMobileMenu(!mobileMenu)
  }
  return (
    <>
      <header id="main-header" className={`home-header ${bgScroll ? "sticky" : `${isHomeRoute ? "hidden" :" "}`}`} >
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <Link to="/">
                <img src={logo} alt="logo" /></Link>
            </div>
            <div class="col-md-8 display_none_on_mobile nav--list">
              <div class="menu-main-header-container">
                <ul id="menu-main-header" class="menu">
                  {
                    HeaderOption.map((item) => {
                      return (
                        <>
                          <li id="menu-item-19" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-19">
                          <NavLink to={item.path} aria-current="page">{item.title}</NavLink>
                          </li>
                        </>
                      )
                    })
                  }

                </ul>
              </div>
            </div>
            <div class="col-md-2">
              {/* <!-- <span class="nav-contact"><i class="fas fa-phone" style="transform:rotate(90deg);"></i>(551) 554 0198</span> --> */}
              <Link to="/start-project" class="lets-work"><strong>Let's Work</strong></Link>
            </div>
          </div>
        </div>
      </header>

      <div class="display__on_mobile">
        <span class="menu_icon_container">
          <AiOutlineMenu onClick={handleClick} style={{color: '#441b72', fontWeight: 'bolder', fontSize: '30px'}}/>
          {/* <i class="fas fa-bars"  onClick={handleClick} ></i> */}
        </span>
        <div className="menu-container_mobile_home" style={{display:`${mobileMenu ? "block" : "none"}`}}  >
          {/* <?php wp_nav_menu( array( 'theme_location' => 'my-custom-menu' ) ); ?> */}
          <div className="menu-main-header-container" >
            <ul id="menu-main-header-3" class="menu">
              {
                HeaderOption.map((item) => {
                  return (
                    <>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-19">
                        <a href={item.path} aria-current="page">{item.title}</a>
                      </li>
                    </>

                  )
                })
              }

            </ul>
          </div>
          <div class="row icons_container_social">
            <ul>
              <li>
                <a href="https://www.facebook.com/VillaexTechnologies/">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/villaextech">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/villaextechnologies/">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/villaex-technologies">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  )
}

export default Header
